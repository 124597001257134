import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";

function Layout({ children }) {
  return (
    <div className='flex flex-col min-h-screen font-sans bg-white text-gray-900'>
      <SEO
        keywords={[
          `bytewrap`,
          `web design`,
          `web development`,
          `mobile apps`,
          `blockchain`,
          `eos dapps`,
        ]}
        title='Your web & mobile apps development company'
      />
      <Header />

      <main className='flex-1 container mx-auto px-8 md:px-16 xl:px-0 xxl:mt-8'>
        {children}
      </main>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
